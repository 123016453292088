//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Custom Variables
//    3. Mixins
//    4. Page Layout
//    5. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables"; 
@import "bootstrap";
@import "fonts";

//---------------------------------------------
// 2. Custom variables (not Bootstrap overrides; see _bootstrap.scss!)
//---------------------------------------------

// // Transitions
// $transitionDefault: 0.5s ease all;
// $transitionLinks: 0.2s ease all;

// Object layering
$zindex-overlay: 1090;

// // Adding to Bootstrap's $font-size-sm
// $font-size-xs: $font-size-base * 0.75; // 12px
// $font-size-mini: $font-size-base * 0.65; // 8ishpx;
// $key-letter-spacing: 0.6px;

//---------------------------------------------
// 4. Page Layout
//---------------------------------------------

.loading-gif {
  z-index: $zindex-overlay; // 1090
  display: flex;
}

body {

  nav {
    z-index: 2;
    #toggle-view-switch {
      span {
        color: white;
        opacity: 0.5;
      }
    }
  }

  //---------------------------------------------
  // Global
  //---------------------------------------------

  // Font family declarations
  .metropolis {
    font-family: "Metropolis", serif;
  }
  .masqualero {
    font-family: "Masqualero", sans-serif;
  }

  #toggle-view-switch {
    display: grid;
    place-content: center;
    #current-view-label {
      color: #ffffffad;
    }
    .form-control {
      font-size: 1.5rem;
      font-weight: bold;
      line-height: 1.5rem;
      display: grid;
      grid-template-columns: 1em auto;
      gap: 0.3em;
    }

    .form-control:focus-within {
      color: $orange;
    }

    input[type="radio"] {
      /* Add if not using autoprefixer */
      -webkit-appearance: none;
      appearance: none;
      /* For iOS < 15 to remove gradient background */
      background-color: transparent;
      /* Not removed via appearance */
      margin: 0;

      font: inherit;
      color: currentColor;
      width: 1em;
      height: 1em;
      border: 0.15em solid currentColor;
      border-radius: 50%;
      transform: translateY(-0.075em);

      display: grid;
      place-content: center;

      &::before {
        content: "";
        width: 0.5em;
        height: 0.5em;
        border-radius: 50%;
        transform: scale(0);
        transition: 400ms transform ease-in-out;
        box-shadow: inset 1em 1em $orange;
        /* Windows High Contrast Mode */
        background-color: CanvasText;
      }
      &:checked {
        &::before {
          transform: scale(1);
        }
      }
    }
     
    // input[type="radio"]:focus {
    //   outline: .15em solid currentColor;
    //   outline-offset: .15em;
    // }

    /* The switch - the box around the slider */
    // .switch {
    //   position: relative;
    //   display: inline-block;
    //   width: 40px;
    //   height: 24px;
    //   /* Hide default HTML checkbox */
    //   input {
    //     opacity: 0;
    //     width: 0;
    //     height: 0;
    //   }
    // }

    /* The slider */
    // .slider {
    //   position: absolute;
    //   cursor: pointer;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   bottom: 0;
    //   background-color: #ccc;
    //   transition: 0.4s;
    //   /* Rounded sliders */
    //   &.round {
    //     border-radius: 24px;
    //     &:before {
    //       border-radius: 50%;
    //     }
    //   }
    //   &:before {
    //     position: absolute;
    //     content: "";
    //     height: 18px;
    //     width: 18px;
    //     left: 4px;
    //     bottom: 3px;
    //     background-color: white;
    //     transition: 0.4s;
    //   }
    // }

    // input:checked + .slider {
    //   background-color: $orange;
    // }

    // input:focus + .slider {
    //   box-shadow: 0 0 1px $orange;
    // }

    // input:checked + .slider:before {
    //   transform: translateX(15px);
    // }
  }

  .carousel-control-prev,
  .carousel-control-next {
    position: relative;
    width: auto;
  }

  .carousel-indicators {
    button {
      color: white;
      text-indent: 0 !important;
      height: 1rem !important;
    }
  }

  // Custom thumbnail buttons
  .btn-thumbnails {
    @include button-outline-variant(
      transparent,
      $almost-black,
      white,
      white,
      $almost-black
    );
    &:hover,
    &:focus,
    &.active {
      img {
        opacity: 0.75;
      }
    }
  }

  #toggle-view-switch {
    display: flex;
  }

  #mobile-carousel-container {
    max-width: 506px;
    #iphoneXRMock {
      z-index: 2;
      display: none !important;
    }
  }
} // this is closing <body> tag
