//---------------------------------------------
//
//  Fonts
//
//    1. Loading Mixins
//    2. Loading Fonts
//
//---------------------------------------------

//---------------------------------------------
// 1. Loading Mixins
//---------------------------------------------

@import "mixins/google-fonts";
@import "mixins/letter-spacing";

//---------------------------------------------
// 2. Loading Fonts
//---------------------------------------------

@include googleFont(
  (
    "name": "Masqualero",
    "folder": "Masqualero",
    "files": "Masqualero",
    "weights": (
      300,
      400,
      700,
    ),
  )
);

@include googleFont(
  (
    "name": "Metropolis",
    "folder": "Metropolis",
    "files": "Metropolis",
    "weights": (
      200,
      300,
    ),
  )
);

//---------------------------------------------
// Custom fonts
//---------------------------------------------
