//---------------------------------------------
//
//  Variables
//
//    1. Bootstrap Fonts
//    2. Bootstrap Globals
//    3. Bootstrap Colors
//    4. Bootstrap Container Width
//
//---------------------------------------------

//---------------------------------------------
// 1. Bootstrap Fonts
//---------------------------------------------

$font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

//---------------------------------------------
// 2. Bootstrap Globals
//---------------------------------------------

$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;

//---------------------------------------------
// 3. Bootstrap Colors
//---------------------------------------------

$grey: #4a4a4a;
$body-color: $grey;
$orange: #ff7706;
$light-grey: #ebedf1;
$almost-black: #232323;

// Revising the Colors utility map:
$theme-colors: (
  "orange": $orange,
  "black": black,
  "almost-black": $almost-black,
  "white": white,
  "grey": $grey,
  "light-grey": $light-grey,
);

//---------------------------------------------
// 4. Bootstrap Container Width
//---------------------------------------------

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
);
