//---------------------------------------------
//
//  Partially loading Bootstrap's components
//
//    1. Functions
//    ** INSERT CUSTOM DEFAULT BOOTSTRAP VARIABLE OVERRIDES HERE **
//    2. Variables
//    3. Mixins
//    4. Root + Reboot
//    5. Type
//    6. Components
//    7. Helpers & Mixins for Breakpoints + Print
//
//---------------------------------------------

//---------------------------------------------
// 1. Functions
//---------------------------------------------

@import "~bootstrap/scss/functions";

//---------------------------------------------
// CUSTOM DEFAULT BOOTSTRAP VARIABLE OVERRIDES
//---------------------------------------------

// Added beyond the py-5 spacing utilities defined by Sass map:
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  // Begin additions here:
  6: $spacer * 5,
  7: $spacer * 7.5,
  8: $spacer * 12,
  9: $spacer * 15,
  10: $spacer * 20,
);

// Updating base font font-family
$font-family-base: "Metropolis", sans-serif;

// Updating button defaults
$btn-font-weight: 600; // semibold
$btn-padding-y: 0.575rem;
$input-btn-focus-box-shadow: 0 0 0 0.05rem rgba(235, 237, 241, 0.5) !important;
$btn-focus-box-shadow: $input-btn-focus-box-shadow;
// $btn-padding-x: 30px;

$input-btn-font-family: "Metropolis", sans-serif;
$btn-font-family: "Metropolis", sans-serif;

$link-decoration: none;
$link-hover-decoration: underline;

$headings-font-family: "Metropolis", sans-serif;

// Changing tooltip display styles
$tooltip-color: black;
$tooltip-bg: $light-grey;
$tooltip-max-width: 300px;

// Offcanvas updates
// $offcanvas-horizontal-width: 500px;

// Carousel updates
$carousel-indicator-active-bg: transparent;

//---------------------------------------------
// 2. Variables
//---------------------------------------------

@import "~bootstrap/scss/variables";

// Add more values to the position helper classes ($position-values utility)
// 1. Create new map
$new-position-values: (
  // 0: 0, // existing in original map
  5: 5%,
  10: 10%,
  15: 15%,
  20: 20%,
  25: 25%,
  30: 30%,
  33: 33%,
  // 50: 50%, // existing in original map
  66: 66%,
  75: 75%,
  80: 80%,
  90: 90%,
  95: 95%,
  // 100: 100% // existing in original map
);

// 2. Merge new and existing maps
$position-values: map-merge($position-values, $new-position-values);

// Adjusting opacity of navbar buttons
$navbar-dark-color: $white;
$navbar-dark-hover-color: rgba($white, 1);
$navbar-dark-disabled-color: rgba($white, 1);

// Headings
$headings-font-family: "Metropolis", sans-serif;

//---------------------------------------------
// 3. Mixins
//---------------------------------------------

@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

//---------------------------------------------
// CUSTOM DEFAULT BOOTSTRAP UTILITY OVERRIDES
//---------------------------------------------

// Generate responsive classes for 'width' classes
// EXAMPLE:  .w-sm-25, .w-md-75
// * Can't just add to the 'width' Sass map since there's no map defined for
// * the utility; hardcoded values in the _utilities.scss file

// 1. Merge in the new property value (33)
// Ex: w-33 now available
$utilities: map-merge(
  $utilities,
  (
    "width":
      map-merge(
        map-get($utilities, "width"),
        (
          values:
            map-merge(
              map-get(map-get($utilities, "width"), "values"),
              (
                33: 33.333%,
              )
            ),
        )
      ),
  )
);

// 2. Give the property (width, the 'w' class) responsive sizes
// Ex: w-md-100, w-lg-25
$utilities: map-merge(
  $utilities,
  (
    "width":
      map-merge(
        map-get($utilities, "width"),
        (
          responsive: true,
        )
      ),
  )
);

// Generate new viewport-height classes
// EXAMPLE:  .vh-25, .vh-33
// * Can't just add to the 'viewport-height' Sass map since there's no map defined for
// * the utility; hardcoded values in the _utilities.scss file

// 1. Merge in the new property values (25vh, 33vh, 50vh, 75vh)
$utilities: map-merge(
  $utilities,
  (
    "viewport-height":
      map-merge(
        map-get($utilities, "viewport-height"),
        (
          values:
            map-merge(
              map-get(map-get($utilities, "viewport-height"), "values"),
              (
                25: 25vh,
                33: 33vh,
                50: 50vh,
                75: 75vh,
              )
            ),
        )
      ),
  )
);

$utilities: map-merge(
  $utilities,
  (
    "viewport-height":
      map-merge(
        map-get($utilities, "viewport-height"),
        (
          responsive: true,
        )
      ),
  )
);

// Adding new font-weight utility classes
// Ex: .fw-medium and .fw-semibold classes
$fw-semibold: 600;
$fw-medium: 500;

$utilities: map-merge(
  $utilities,
  (
    "font-weight":
      map-merge(
        map-get($utilities, "font-weight"),
        (
          values:
            map-merge(
              map-get(map-get($utilities, "font-weight"), "values"),
              (
                semibold: $fw-semibold,
                medium: $fw-medium,
              )
            ),
        )
      ),
  )
);

// Adding new line-height classes
// ex: .lh-md = 1.7x instead of .lh-lg (2x)
$line-height-med: 1.7;

$utilities: map-merge(
  $utilities,
  (
    "line-height":
      map-merge(
        map-get($utilities, "line-height"),
        (
          values:
            map-merge(
              map-get(map-get($utilities, "line-height"), "values"),
              (
                md: $line-height-med,
              )
            ),
        )
      ),
  )
);

$utilities: map-merge(
  $utilities,
  (
    "min-viewport-height": (
      property: min-height,
      class: min-vh,
      responsive: true,
      values: (
        100: 100vh,
        50: 50vh,
        75: 75vh,
      ),
    ),
  )
);

//---------------------------------------------
// 4. Root + Reboot
//---------------------------------------------

@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";

//---------------------------------------------
// 5. Type
//---------------------------------------------

@import "~bootstrap/scss/type";

//---------------------------------------------
// 6. Components
//---------------------------------------------

@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
// @import "~bootstrap/scss/tables";
// @import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
// @import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
//@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
//@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
//@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
//@import "~bootstrap/scss/toasts";
// @import "~bootstrap/scss/modal";
@import "~bootstrap/scss/offcanvas";
// @import "~bootstrap/scss/tooltip";
//@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";

//---------------------------------------------
// 7. Helpers & Mixins for Breakpoints + Print
//---------------------------------------------

@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/utilities/api";
